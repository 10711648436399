
import { Injectable } from "@angular/core";
import { ClCompanySwitcherService } from "./cl-company-switcher.service";
import { Account } from '@mitel/cloudlink-sdk/admin';
import { Odata } from '@mitel/cloudlink-sdk';


/**
 * This class is exposed to the consumer of this Header Bar component
 */
@Injectable({
    providedIn: 'root'
})
export class CompanySwitcherService {

    constructor(private wrappedService: ClCompanySwitcherService) {}
   

    /**
     * Change the currently selected company.
     * 
     * @param newCurrent 
     */
    public setCurrentAccount(newCurrent: Account) {
        this.wrappedService.setCurrentAccount(newCurrent);
    }

    /**
     * Change the currently selected company. Will query provider for account and then call setCurrentAccount().
     * 
     * @param accountId 
     */
    public setCurrentAccountById(accountId: string) {
        this.wrappedService.setCurrentAccountById(accountId);
    }


    /**
     * Get array of active companies. Note that companies are also refered to as accounts.
     */
    public getAccounts(odata?: Odata): Promise<Account[]>{
        return this.wrappedService.getAllAccounts(odata);
    }

}