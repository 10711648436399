import { Injectable } from '@angular/core';
import { AccountCollection, Account, Partner } from '@mitel/cloudlink-sdk/admin';
import { Config, AdminService, Odata, Utils } from '@mitel/cloudlink-sdk';
import { userRoles } from '../cl-side-nav/constants';
import { Subject } from 'rxjs';
import {ClLogService} from '../cl-log/cl-log.service';

@Injectable({
  providedIn: 'root'
})
export class ClCompanySwitcherService {
  private api: AdminService;

   private currentAccount : Account;
  /**
   * Subject fired when currentAccount is changed.
   *
   * @memberof ClCompanySwitcherService
   */
  private currentAccountChanged = new Subject<Account>();
  getSubjectCurrentAccountChanged():Subject<Account> {
    return this.currentAccountChanged;
  }

  constructor(private log: ClLogService) {
  }

  public setCurrentAccount(newCurrent : Account){
    this.log.debug('[ACCOUNT SWITCHER]: setting account  -- ', newCurrent);

    // change currentAccount
    this.currentAccount = newCurrent;
    this.currentAccountChanged.next(newCurrent);

  }

  public setCurrentAccountById(accountId: string) {
    this.log.debug('[ACCOUNT SWITCHER]: SetcurrentById setting account  -- ', accountId);

    // change currentAccount
    this.getAccount(accountId).then((account: Account) => {
      return this.setCurrentAccount(account);
      });
  }

  public getCurrentAccount():Account{
    this.log.debug('[ACCOUNT SWITCHER]: getting account  -- ');
    return this.currentAccount ? JSON.parse(JSON.stringify(this.currentAccount)) : null;   // clone
  }

  /**
   * This must be called early to initialize the Admin Service client API.
   * @param cloudType
   * @returns internal AdminService api. This is only useful to unit tests that need to mock this api.
   */
  public setCloudType( cloudType: string) {
    Config.cloud = cloudType;
    return this.api = new AdminService();  // hack; so that unit tests can mock AdminService
  }

  public getPartner(partnerId: string, options?: any): Promise<Partner> {
      return this.api.getPartner({ partnerId, options });
  }

  public getAccount(accountId: string, options?: any): Promise<Account> {
      return this.api.getAccount({accountId, options});
  }

  public getAllAccounts(odata?: Odata, options?: any): Promise<Account[]> {
    const promise = new Promise<Account[]>(async (resolve, reject) => {
      let accounts: Account[] = [];
      try {
        const accountCollection = await this.getAccounts(odata, options);
        if (accountCollection && accountCollection.count > 0 && accountCollection._embedded && accountCollection._embedded.items) {
          accounts = accountCollection._embedded.items
          const next = Utils.getOdataNext(accountCollection);
          if (next && next.$SkipToken) {
            const moreAccounts = await this.getAllAccounts(next, options);
            if (moreAccounts) {
              accounts = accounts.concat(moreAccounts);
            }
          }
        }
        resolve(accounts.filter(account => (account && account.active)));
      } catch (err) {
        reject(err);
      }
    });

    return promise;
  }

  public getAccounts(odata?: Odata, options?: any): Promise<AccountCollection> {
    if (odata) {
      return this.api.getAccounts({
        $Expand: odata.$Expand, $Skip: odata.$Skip,
        $Top: odata.$Top, $SkipToken: odata.$SkipToken, options
      });
    } else {
      return this.api.getAccounts({ options });
    }
  }

  public shouldShow(currentUserRole: string) {
    return currentUserRole === userRoles.PARTNER_ADMIN ||
      currentUserRole === userRoles.PARTNER_GROUP_ADMIN;
  }

}
