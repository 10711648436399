import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthenticationService, Config, UserClaims, Token, Claims } from '@mitel/cloudlink-sdk';
import { ClLogService} from '../cl-log/cl-log.service';


@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public auth: AuthenticationService;

    constructor(private log: ClLogService) {

    }

    public setCloud( cloudType: string )
    {
        this.log.debug("Setting the cloud type", cloudType );
        Config.cloud = cloudType;
        Config.authentication = null;
        this.auth = Config.authentication;
        this.log.debug("Just set this.auth ", this.auth);
    }

    public getToken(token?: Token): Promise<Token>
    {
        return this.auth.getToken(token);
    }

    public setToken(token: Token): Promise<Token>
    {
        return this.auth.setToken(token);
    }

    public whoAmI(token?:Token): Promise<Claims>
    {
        return this.auth.whoAmI(token);
    }

    public login( authCode: string, clientId: string, clientURL: string, scope: any ): Promise<Token>
    {
        var param = {
            code : {
                code: authCode,
                clientId: clientId,
                redirect_uri: clientURL,
                scope: scope
            }
        };
        return this.auth.login(param);

    }

    public logout()
    {
        return this.auth.logout();
    }

    public getPartnerClaims(): Promise<Claims>
    {
        // check for an "original_token"
        let token = localStorage.getItem('@mitel/token.original_token');
        if (!token ) {
            // there is no original token, so return null
            return Promise.resolve(null);
        }
        return this.auth.whoAmI( JSON.parse(token) );
    }

    public getPartnerToken(): Token
    {
        let tokenStr = localStorage.getItem('@mitel/token.original_token');
        if ( tokenStr )
            return  JSON.parse(tokenStr); 

        return null;
    }
}

