import { Injectable } from '@angular/core';
import {S3ServiceStub} from './s3.service.stub';
import { S3Service} from '../lib/cl-s3/s3.service';
import {ClLogEntry} from '../lib/cl-log/cl-log-entry';
import {S3Log} from "../lib/cl-s3/s3.log";
import {S3Config} from "../lib/cl-s3/s3-config";


enum URL {
  LOG = 0,
  FILE = 1,
  EXCEPTION = 2
}


@Injectable()
export class LogBatchServiceStub {

  constructor(private s3: S3Service) {
  }

  public log (log: S3Log) {
  }

  public logException (log: S3Log) {
  }

  private addToBatch(log: S3Log, urlroute: URL) {
  }

  private loadPipe(log: S3Log) {
  }

  private loadBackupPipe(log: S3Log) {
  }

}
