import {formatDate} from '@angular/common';

export enum Level {
  All,
  Debug,
  Info,
  Warn,
  Error,
  Exception,
  NoSoftware,
  Audit,
  AllDisabled
}

export class ClLogEntry {
  timestamp: Date = new Date();
  message = '';
  additionalInfo: any[] = [];
  msgId = '';
  protected level: Level;
  appCompId = '';
  userId = '';
  accountId = '';


  constructor (level: Level, msg: string, appCompId?: string, accountId?: string, userId?: string, ...params: any[]) {
    this.appCompId = appCompId;
    this.userId = userId;
    this.accountId = accountId;
    this.level = level;
    this.message = msg;
    this.additionalInfo = params;
  }


  addContextsToLog(): string {
    let contextStr = '';
    if ((this.appCompId !== undefined) &&
      (this.appCompId !== '')) {
      contextStr += '\nSource: ' + this.appCompId;
    }

    if ((this.accountId !== undefined) &&
      (this.accountId !== '')) {
      contextStr += '\nAccount: ' + this.accountId;
    }

    if ((this.userId !== undefined) &&
      (this.userId !== '')) {
      contextStr += '\nUser: ' + this.userId;
    }
    return (contextStr);
  }

  logToString(): string {
    let stringLog = this.formatBaseMessage();
    stringLog += this.getAdditionalAsString();
    return stringLog;
  }

  formatInfo(extraInfo: any[]): string {
    let returnStr: string = extraInfo.join(',');

    if (extraInfo.some(p => typeof p === 'object')) {
      returnStr = '';
      for (const info of extraInfo) {
        if (info.length !== 0 ) {
          returnStr += JSON.stringify(info, null, '    ') + ', ';
        }
      }
    }
    return returnStr;
  }

  getLogLevel(): Level {
    return(this.level);
  }

  formatBaseMessage(): string {
    let baseMsg = '';
    this.timestamp = new Date();

    baseMsg = formatDate(this.timestamp, 'short', 'en') + '\nLevel : ' + Level[this.level].toUpperCase();
    baseMsg += this.addContextsToLog();
    baseMsg += '\nLog: ' + this.message;
    return baseMsg;
  }

  getAdditionalInfo (): any[] {
    if (this.additionalInfo.length === 0) {
      this.additionalInfo.push(''); // ease of display, don't return 1 item formatted as an array
    }
    if (this.additionalInfo.length === 1) {
      return this.additionalInfo[0]; // ease of display, don't return  single item formatted as an array
    } else {
      return this.additionalInfo;
    }
  }

  getAdditionalAsString(): string {
    let extraInfoString = '';
    if ( (this.additionalInfo !== undefined) &&
      (this.additionalInfo.length > 0) ) {
      extraInfoString += this.formatInfo(this.additionalInfo);
    }
    return(extraInfoString);
  }
}


export class ClAuditLogEntry extends ClLogEntry {
  private actionType = '';
  constructor (msg: string, appCompdId?: string, accountId?: string, userId?: string, ...params: any[]) {
    super(Level.Audit, msg, appCompdId, accountId, userId, ...params);
  }
}

export class ClExceptionLogEntry extends ClLogEntry {
  error: Error;

  constructor (msg: string, error: Error, appCompdId?: string, accountId?: string, userId?: string, ...params: any[]) {
    params.unshift(error); // pop the Error object into the start of params so they can be handled through base references
    super(Level.Exception, msg, appCompdId, accountId, userId,  ...params);
    this.error = error;
  }

  logToString(): string {
    let stringLog = '';

    this.timestamp = new Date();
    stringLog = formatDate(this.timestamp, 'short', 'en') + '\nLevel : ' + Level[this.level].toUpperCase();
    stringLog += this.addContextsToLog();
    stringLog += '\nException: ' + this.message;

    if ( (this.additionalInfo !== undefined) &&
      (this.additionalInfo.length > 0) ) {
      stringLog += '\n' + this.formatInfo(this.additionalInfo);
    }
    return stringLog;
  }

  formatBaseMessage(): string {
    let baseMsg = '';
    this.timestamp = new Date();

    baseMsg = formatDate(this.timestamp, 'short', 'en') + '\nLevel : ' + Level[this.level].toUpperCase();
    baseMsg += this.addContextsToLog();
    baseMsg += '\nException Log: ' + this.message + '\n';
    return baseMsg;
  }
}
