import { Component, Input, OnInit } from '@angular/core';
import {AppContainer, AppContainerPrivate} from '../app-container';
import { CloudLinkHeaderAuthService } from '../../cl-header/cl-header-auth.service';
import {ClApplauncherService} from "../cl-applauncher.service";
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'cl-appcontainer',
  template: `<div *ngIf="app.selected">
  <li tabindex="0" class="selected" (keydown.enter)="openNewTab($event)" (keydown.space)="openNewTab($event)">
    <a (click)="openNewTab($event);" target="_selected"><div style="height: 55px;"><img src="{{app.img64}}" alt="MitelImg"></div>{{app.label}}</a>
  </li>
</div>
<div *ngIf="!app.selected">
  <li tabindex="0" (keydown.enter)="openNewTab($event)" (keydown.space)="openNewTab($event)">
    <a (click)="openNewTab($event);"><div style="height: 55px;"><img src="{{app.img64}}" alt="MitelImg"></div>{{app.label}}</a>
  </li>
</div>
`,
  styles: [`li{float:left;width:90px;text-align:center;line-height:1;padding:7px 5px}.selected{border:1px solid #0073d0;border-radius:4px;background-color:#f5fafd;color:#0073d0}img{width:50px}li a{font-size:12px;line-height:1;color:#3a393a;cursor:pointer}a[target=_selected]{color:#0073d0!important}:host{display:inline-block;height:90px}`]
})
export class ClAppcontainerComponent implements OnInit {

  @Input() app : AppContainerPrivate;
  @Input() dynamic: boolean;
  @Input() popover: NgbPopover;


  constructor(private headerAuth : CloudLinkHeaderAuthService,
              private appLauncherService: ClApplauncherService) {
  }

  ngOnInit(): void {}

  public openNewTab(event) {
    const interaciveLog = (this.dynamic)? 'DYNAMIC MODE ENABLED' : 'NORMAL MODE ENABLED';
    console.log('[CL-APPCONTAINER]:*** invoke another application in a new tab ', interaciveLog);

    if (this.dynamic) {
      event.stopPropagation();
      if (this.app.id || this.app.id === 0) {
        this.appLauncherService.registerAppClick(this.app.id);
      } else {
        console.warn('[CL-APPCONTAINER]: No ID set for app "'+  this.app.label + '". Using label instead. Please set the app ID to better determine which app registered this click.');
        this.appLauncherService.registerAppClick(this.app.label);
      }
    } else {
      this.popover.close();
      if (!this.app.url || this.app.url.length === 0) {
        console.error('[CL-APPCONTAINER]: No URL provided. Please provide a URL for this app');
      } else  {
        this.headerAuth.openNewApplicationInTab( this.app.url );
      }
    }
  }

}
