import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ClSideNavService } from '../cl-side-nav.service';
//import { userRoles } from '../constants';

@Component({
  selector: 'cl-side-nav-item',
  template: `<div class="menu-item" [ngClass]="{'active': defaultItemId === item.id, 'focus': isFocused(), 'open': !collapsed && item.children && item.children.length > 0}">
	<div class="left-indicator" *ngIf="defaultItemId === item.id"></div>
	<div class="clickable" (click)="onItemClick()">
		<div class="image">
			<img *ngIf="defaultItemId !== item.id && !isFocused(); else active_image" [src]="sanitizer.bypassSecurityTrustResourceUrl(getIconInactive())">
			<ng-template #active_image>
				<img [src]="sanitizer.bypassSecurityTrustResourceUrl(getIconActive())">
			</ng-template>
		</div>
		<button type="button" 
				[id]="item.id" 
				class="btn btn-link menu-text" 
				[ngbTooltip]="isEllipsisActive(item.id) ? item.name : '' "
				(keydown.enter)="$event.preventDefault(); onItemClick()" 
				(keydown.space)="$event.preventDefault(); onItemClick()" 
				[attr.tabindex]="showSideNav ? 0 : -1">{{ item.name }}
		</button>
		<div class="image-warning" *ngIf="item.status === 'warning'">
			<img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDIwIDE4Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZmlsbD0iI0Y4Q0EwMCIgZD0iTTExLjc1MiAxLjE4Nmw3LjYxOCAxMy44NUEyIDIgMCAwIDEgMTcuNjE3IDE4SDIuMzgzQTIgMiAwIDAgMSAuNjMgMTUuMDM2bDcuNjE4LTEzLjg1YTIgMiAwIDAgMSAzLjUwNCAweiIvPgogICAgICAgIDxwYXRoIGZpbGw9IiNGRUZBRTUiIGQ9Ik0xMCA0YTEgMSAwIDAgMSAxIDF2NmExIDEgMCAwIDEtMiAwVjVhMSAxIDAgMCAxIDEtMXpNMTAgMTRhMSAxIDAgMSAxIDAgMiAxIDEgMCAwIDEgMC0yeiIvPgogICAgPC9nPgo8L3N2Zz4K" alt="warning">
		</div>
	</div>
	<div *ngIf="item.children && item.children.length > 0" 
		 class="arrow" [attr.tabindex]="showSideNav ? 0 : -1" 
		 (click)="onItemCollapseClick()" 
		 (keydown.enter)="onItemCollapseClick()" 
		 (keydown.space)="$event.preventDefault(); onItemCollapseClick()">
		<img [ngClass]="{'arrow-down': collapsed, 'arrow-up': !collapsed}" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iN3B4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDcgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTEuMSAoNTc1MDEpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPmFycm93PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlNjcmVlbnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSIxMjAwcHgtU3ViLU5hdiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIxNC4wMDAwMDAsIC0yNTAuMDAwMDAwKSI+CiAgICAgICAgICAgIDxnIGlkPSJhcnJvdyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjA5LjAwMDAwMCwgMjQ0LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZS01IiBmaWxsLXJ1bGU9Im5vbnplcm8iIHg9IjAiIHk9IjAiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiI+PC9yZWN0PgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTcsMTEgTDEwLjUwMjMwMTIsMTEgQzEwLjc3NzE3MjcsMTEgMTEsMTEuMjMxOTMzNiAxMSwxMS41IEMxMSwxMS43NzYxNDI0IDEwLjc4NTE1NjIsMTIgMTAuNTAyMzAxMiwxMiBMNi40OTc2OTg3OCwxMiBDNi4zNjA2NTU4NiwxMiA2LjIzNjU0OTcsMTEuOTQyMzQ3NiA2LjE0NjU0NTY4LDExLjg1MTMwODUgQzYuMDU1NjUzOTIsMTEuNzY3NDYyIDYsMTEuNjQzMzM1OSA2LDExLjUwMjMwMTIgTDYsNy40OTc2OTg3OCBDNiw3LjIyMjgyNzM0IDYuMjMxOTMzNTksNyA2LjUsNyBDNi43NzYxNDIzNyw3IDcsNy4yMTQ4NDM3NSA3LDcuNDk3Njk4NzggTDcsMTEgWiIgaWQ9IkRyb3Bkb3duLUFycm93IiBmaWxsPSIjRkZGRkZGIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjUwMDAwMCwgOS41MDAwMDApIHNjYWxlKDEsIC0xKSByb3RhdGUoLTQ1LjAwMDAwMCkgdHJhbnNsYXRlKC04LjUwMDAwMCwgLTkuNTAwMDAwKSAiPjwvcGF0aD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+">
	</div>
</div>
<ul *ngIf="item.children && item.children.length > 0" class="collapse list-unstyled" [ngClass]="{'show': isExpanded() || !collapsed}">
    <li *ngFor="let subItem of item.children">
        <cl-side-nav-sub-item *ngIf="sideNavSvc.shouldShowItem(subItem.minRole)" [item]="subItem" [defaultItemId]="defaultItemId" (setItemDefault)="emitDefaultItemChange($event)" [showSideNav]="showSideNav"></cl-side-nav-sub-item>
    </li>
</ul>
`,
  styles: [`.menu-item{height:50px;width:100%;padding:0 18px;outline:0!important}.menu-item.active,.menu-item.focus,.menu-item.open,.menu-item:hover{background-color:#11284c}.left-indicator{display:inline-block;width:5px;height:50px;background-color:#00a1e0;border-radius:2px;margin-right:-5px;float:left;margin-left:-18px}.clickable{display:inline-block;width:90%;cursor:pointer;white-space:nowrap}.image{display:inline-block;height:50px;margin-right:17px}.image img{width:23px;height:23px}.image-warning{display:inline-block;float:right;margin-top:12px}.image-warning img{width:16px}.menu-item button{display:inline-block;font-family:'Open Sans';font-weight:600;font-size:13px;color:#fff;text-decoration:none;outline:0;line-height:50px;padding:0;margin-right:20px;border:none!important;height:inherit}.menu-item.active button,.menu-item.focus button{color:#00a1e0}.menu-item button:focus{outline:0;box-shadow:0 0 0 .2rem rgba(59,153,252,.25);outline:-webkit-focus-ring-color auto 5px}.arrow{display:inline-block;float:right;margin-top:12px;width:10%;text-align:right;cursor:pointer}.arrow:focus{outline:0;box-shadow:0 0 0 .2rem rgba(59,153,252,.25);outline:-webkit-focus-ring-color auto 5px}.arrow-down{-webkit-transform:scaleY(-1);transform:scaleY(-1);-webkit-filter:FlipV;filter:FlipV;-ms-filter:FlipV}.menu-text{text-overflow:ellipsis;overflow:hidden;white-space:nowrap;max-width:130px;text-align:left}`]
})
export class ClSideNavItemComponent implements OnInit {
	@Input() item: any = {};
  @Input() defaultItemId: string;
  @Input() showSideNav: boolean;

  @Output() setItemDefault = new EventEmitter<string>();
  //  currentExpanded will be used to track which menu item (if any) has been expanded and to
  // build dependency between menu items.
  // the defaultItem is the currently selected menu item which takes menu focus and may
  // or may not be collapsed, while the currentExpanded item is always !collapsed.  This event
  // allows the parent side nav to set communicate to all items who the currentExpanded is.
  // There can be a maximum of two menu items expanded at a time, as long as one of them is the active one.
  // There can only be one non-active menu item open at a time
  @Input() currentExpanded: string;
  @Output() setCurrentExpanded = new EventEmitter<string>();
	collapsed: boolean = true;


  constructor(public sideNavSvc: ClSideNavService,
    public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.collapsed = this.item.collapsed || true;

    if (this.isFocused()) {
      this.collapsed = false;
    }
    
    if (!this.currentExpanded) {
      this.currentExpanded = '';
    }
  }

  isFocused() {
    let found = false;

    if (this.item.id === this.defaultItemId) {
      return true;
    }
    if (this.item.children && this.item.children.length > 0) {
      this.item.children.forEach(subItem => {
        if (subItem.id === this.defaultItemId) {
          found = true;

          return;
        }
      });
    }

    return found;
  }

  isExpanded(): boolean {
    let expanded = false;
    if ( (this.item.id === this.currentExpanded)) {
        expanded = true;
    } else {
      // if we are not the default item and not the last manually expanded item we should collapse
      if (!this.isFocused()) {
          this.collapsed = true;
      }
    }

    return (expanded);
  }

  onItemClick() {
    // Set default selected item

    this.collapsed = !this.collapsed;
    this.emitDefaultItemChange(this.item.id);
    if (!this.collapsed) {
      this.emitNewExpandedItem(this.item.id);
    } else if (this.item.id === this.currentExpanded) {
      this.emitNewExpandedItem('');
    }
  }

  emitNewExpandedItem(id: string) {
    this.setCurrentExpanded.emit(id);
}
  emitDefaultItemChange(id: string) {
    this.setItemDefault.emit(id);
  }

  onItemCollapseClick() {
    this.collapsed = !this.collapsed;
    if (!this.collapsed) {
        this.emitNewExpandedItem(this.item.id);
    } else if (this.item.id === this.currentExpanded){
        this.emitNewExpandedItem('');
    }
  }

  getIconInactive() {
    if (this.item && this.item.iconInactive) {
      return this.item.iconInactive;
    } else {
      return "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMyIgaGVpZ2h0PSIyMyIgdmlld0JveD0iMCAwIDIzIDIzIj4KICAgIDxyZWN0IHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgeD0iMi41IiB5PSIyLjUiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjQ0NDRENFIiByeD0iOSIvPgo8L3N2Zz4K";
    }
  }

  getIconActive() {
    if (this.item && this.item.iconActive) {
      return this.item.iconActive;
    } else {
      return "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMyIgaGVpZ2h0PSIyMyIgdmlld0JveD0iMCAwIDIzIDIzIj4KICAgIDxyZWN0IHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgeD0iMi41IiB5PSIyLjUiIGZpbGw9IiM5OUQ5RjMiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjMDBBMUUwIiByeD0iOSIvPgo8L3N2Zz4K";
    }
  }

  isEllipsisActive(id) {
    const thisElement = document.getElementById(id);
    return (thisElement ? thisElement.offsetWidth < thisElement.scrollWidth : false);
  }
}
