import { Injectable } from '@angular/core';
import {ClLogEntry, ClAuditLogEntry, ClExceptionLogEntry, Level } from '../lib/cl-log/cl-log-entry';
import {ClLogConfig, IClLogConfig, ClLoggerDetail, ClLogOutput } from '../lib/cl-log/cl-log-config';
import { Observable, of } from 'rxjs';
import { HttpClientTestingModule, HttpTestingController } from '@angular/common/http/testing';
import {ClLogEndpoint, ClConsoleEndpoint } from '../lib/cl-log/cl-log-endpoint';
import { LogBatchServiceStub } from './batch.service.stub';


export enum LogType {
  software = 0,
  audit = 1,
  exception = 2
}

export class ClLogRoute {
  routeType: LogType;
  endpoints: ClLogEndpoint[] = [];

  constructor (type: LogType, endpoint: ClLogEndpoint) {
    this.routeType = type;
    this.endpoints.push(endpoint);
  }

  addEndpoint(endpoint: ClLogEndpoint) {
    let newEndpoint = true;
    for (const endp of this.endpoints) {
      if (endp.getType() === endpoint.getType()) {
        newEndpoint = false;
        break;
      } else {
        // carry on looking
      }
    }
    if (newEndpoint === true) {
      this.endpoints.push(endpoint);
    }
  }
}

@Injectable({
  providedIn: 'root'
})


export class ClLogServiceStub {

  loggingThreshold: Level = Level.Warn;
  loggingConfig = new ClLogConfig();
  endpoints: ClLogEndpoint[] = [];
  logRoutings: ClLogRoute[] = [];
  sourceContext = '';
  userContext = '';
  accountContext = '';
  stubLogConfig =  {
    loggers :  [{name: 'console',
      enabled: 'true'},
      ],
    logOutputs: [{type: 'software', loggerList: ['console']},
      {type: 'audit', loggerList: ['console']},
      {type: 'exception', loggerList: ['console']}],
    logLevel: 'Warn', };

  constructor(private http: HttpClientTestingModule, private batchLogger: LogBatchServiceStub) {
    this.initLogging();
  }

  setSourceContext(appName: string) {
    if (appName !== undefined) {
      this.sourceContext = appName;
    }
  }

  setUserContext(userId: string) {
    if (userId !== undefined) {
      this.userContext = userId;
    }
  }

  setAccountContext(accountId: string) {
    if (accountId !== undefined) {
      this.accountContext = accountId;
    }
  }

   isSoftwareLog(level: Level) {
    let rc = false;
    if (level <= Level.Error) {
      rc = true;
    }
    return rc;
  }

  isAuditLog(level: Level) {
    return (level === Level.Audit);
  }

  isExceptionLog(level: Level) {
    return (level === Level.Exception);
  }

  getLogTypeFromLevel(logLevel: Level): LogType {
    let logType = LogType.software;

    if (this.isAuditLog(logLevel)) {
      logType = LogType.audit;
    } else if (this.isExceptionLog(logLevel)) {
      logType = LogType.exception;
    }
    return(logType);
  }

  publishLog(logEntry: ClLogEntry) {
        const logLevel = logEntry.getLogLevel();
        if (this.isThisLevelLogged(logLevel)) {
          switch (logEntry.getLogLevel()) {
            case (Level.Error):
              console.error(logEntry.logToString());
              break;
            case (Level.Exception):
              console.error(logEntry.logToString());
              break;
            case (Level.Warn):
              console.warn(logEntry.logToString());
              break;
            case (Level.Audit):
            case (Level.Debug):
            case (Level.Info):
            default:
              console.log(logEntry.logToString());
          }
      }
    }

  isThisLevelLogged(logLevel: Level): boolean {
    let isLogged = true;
    if (logLevel < this.loggingThreshold) {
      isLogged = false;
    }
    return (isLogged);
  }


  log(level: Level, msg, string, ...params: any[]) {
      this.publishLog(new ClLogEntry( level, msg, this.sourceContext, this.accountContext, this.userContext, ...params));
  }

  auditLog(msg: string, ...params: any[]) {

      const auditLog = new ClAuditLogEntry(msg, this.sourceContext, this.accountContext, this.userContext, ...params);
      this.publishLog(auditLog);
  }

  info(msg: string, ...params: any[]) {
      const infoLog = new ClLogEntry(Level.Info, msg, this.sourceContext, this.accountContext, this.userContext, ...params);
      this.publishLog(infoLog);
  }

  debug(msg: string, ...params: any[]) {
      this.publishLog(new ClLogEntry(Level.Debug, msg, this.sourceContext, this.accountContext, this.userContext, ...params));
  }

  warn(msg: string, ...params: any[]) {
      this.publishLog(new ClLogEntry(Level.Warn, msg, this.sourceContext, this.accountContext, this.userContext, ...params));
  }

  error(msg: string, ...params: any[]) {
      this.publishLog(new ClLogEntry(Level.Error, msg, this.sourceContext, this.accountContext, this.userContext,  ...params));
  }

  exception(msg: string, error: Error, ...params: any[]) {
      this.publishLog(new ClExceptionLogEntry(msg, error, this.sourceContext, this.accountContext, this.userContext,  ...params));
  }


  initLogging() {
    try {
      this.getLoggingConfig().subscribe((logCfg: IClLogConfig) => {
          this.cloneConfigData(this.loggingConfig, logCfg);
          this.updateLoggingConfig();
        },
        error1 => {
          this.warn('Failed load config for log service');
        });
    } catch (err) {
      this.exception('Failed to get logging config ', err);
    }
  }

  cloneConfigData(destConfig: ClLogConfig, ifData: IClLogConfig) {
    destConfig.logLevel = ifData.logLevel;
    for (const logger of ifData.loggers) {
      destConfig.loggers.push(logger);
    }

    for (const output of ifData.logOutputs) {
      destConfig.logOutputs.push(output);
    }
  }
  updateLoggingConfig() {
    this.initLogEndpointTypes();
    this.initLogOuputs();
    this.initLoggingThreshold();
  }

  private initLogEndpointTypes() {

    let logEndpoint: ClLogEndpoint;

    for (const logger of this.loggingConfig.loggers) {
      if (logger.enabled === 'true') {
        const loggerName = logger.name.toLowerCase();
        switch (loggerName) {
          case 'console':
          case 'cloudlink-logger':   // for the stub class it will always be console
            logEndpoint = new ClConsoleEndpoint();
            logEndpoint.setType(loggerName);
            this.endpoints.push(logEndpoint);
            break;
          default:
            console.log('Unknown logger type specified' + loggerName);
            break;
        }
      }
    }
  }

  initLoggingThreshold() {
    const stringlevel = this.loggingConfig.logLevel.toString().toLowerCase();
    let index = 0;
    for (const lt of Object.keys(Level)) {
      if (Level[index].toLowerCase() === stringlevel) {
        this.loggingThreshold = Level[Level[index]];
        break;
      }
      // since we have two keys for each enum entry, only increment on the number keys
      const parseIndex = parseInt(lt, 10);
      if (!(parseIndex !== parseIndex)) { // NaN is never equal to itself
        index++;
      }
    }
  }

  getEndpointTypeFromName(name: string) {
    let theEndpoint: ClLogEndpoint;

    for (const endpoint of this.endpoints) {
      if (name === endpoint.typeName.toLowerCase()) {
        theEndpoint = endpoint;
        break;
      }
    }
    return (theEndpoint);
  }

  addOutputsToRouting(logType: LogType, loggerList: string[]) {
    // get the Endpoint here if it exists

    for (const logger of loggerList) {
      const endpoint = this.getEndpointTypeFromName(logger);
      if (endpoint) {
        if (this.logRoutings[logType]) {
          this.logRoutings[logType].addEndpoint(endpoint);
        } else {
          this.logRoutings[logType] = new ClLogRoute(logType, endpoint);
        }
      }
    }
  }

  initLogOuputs() {

    for (const output of this.loggingConfig.logOutputs) {
      let logType: LogType;
      const type = output.type.toLowerCase();
        switch (type) {
          case (LogType[LogType.audit]):
            logType = LogType.audit;
            this.addOutputsToRouting(logType, output.loggerList);
            break;
          case (LogType[LogType.software]):
            logType = LogType.software;
            this.addOutputsToRouting(logType, output.loggerList);
           break;
          case (LogType[LogType.exception]):
            logType = LogType.exception;
            this.addOutputsToRouting(logType, output.loggerList);
            break;
        }
      }
  }

  getLoggingConfig(): Observable<ClLogConfig> {
    return(of(this.stubLogConfig));
  }


  getEndpointsListForLogType(logType: LogType): ClLogEndpoint[] {
    let endpoints: ClLogEndpoint[];

    if (this.logRoutings[logType]) {
    endpoints = this.logRoutings[logType].endpoints;
    }
    return endpoints;
  }
}


