
export class S3Log {
  timestamp: Date = new Date();
  message = '';
  additionalInfo: any[] = [];
  msgId = '';
  level: string;
  appCompId = '';
  userId = '';
  source: any[] = [];

  constructor (level: string, msg: string, time = new Date(), appCompId?: string, msgId?: string, userId?: string, params?: any[], source?: any[])
  {
    this.timestamp = time;
    this.appCompId = appCompId;
    this.userId = userId;
    this.level = level;
    this.message = msg;
    this.additionalInfo = params;
    this.source = source;
  }

}

export enum S3LogLevel {
  Debug,
  Info,
  Exception,
  Audit
}

export interface RequestBody {
  source: string,
  logLevel: string,
  title: string,
  description?: string,
  type: string,
  logs: S3Log[]
}
