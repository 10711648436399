import { Injectable } from '@angular/core';
import {ClLogEntry, ClAuditLogEntry, ClExceptionLogEntry, Level } from './cl-log-entry';
import {ClLogConfig, IClLogConfig, ClLoggerDetail, ClLogOutput } from './cl-log-config';
import {ClLogEndpoint, ClConsoleEndpoint, S3LoggerEndpoint} from './cl-log-endpoint';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {LogBatchService} from '../cl-s3/batch.service';
import {S3Log} from '../cl-s3/s3.log';


const LOGGER_CFG_FILE = 'assets/cl-console-components/config/cl-log-config.json';


export enum LogType {
  software = 0,
  audit = 1,
  exception = 2
}

export interface TestData {
   logLevel: string;
}

export class ClLogRoute {
  routeType: LogType;
  endpoints: ClLogEndpoint[] = [];

  constructor (type: LogType, endpoint: ClLogEndpoint) {
    this.routeType = type;
    this.endpoints.push(endpoint);
  }

  addEndpoint(endpoint: ClLogEndpoint) {
    let newEndpoint = true;
    for (const endp of this.endpoints) {
      if (endp.getType() === endpoint.getType()) {
        newEndpoint = false;
        break;
      } else {
        // carry on looking
      }
    }
    if (newEndpoint === true) {
      this.endpoints.push(endpoint);
    }
  }
}

@Injectable({
  providedIn: 'root'
})

export class ClLogService {

  loggingThreshold: Level = Level.Warn;
  loggingConfig = new ClLogConfig();
  endpoints: ClLogEndpoint[] = [];
  logRoutings: ClLogRoute[] = [];
  testResp: TestData;
  sourceContext = 'CL Common Components Library';
  userContext = '';
  accountContext = '';
  configFile = LOGGER_CFG_FILE;
  serviceInitialized = false;

  constructor(private http: HttpClient, private batchLogger: LogBatchService) {
   // this.initLogging();
  }

  setSourceContext(appName: string) {
    if (appName !== undefined) {
      this.sourceContext = appName;
    }
  }

  setUserContext(userId: string) {
    if (userId !== undefined) {
      this.userContext = userId;
    }
  }

  setAccountContext(accountId: string) {
    if (accountId !== undefined) {
      this.accountContext = accountId;
    }
  }

   isSoftwareLog(level: Level) {
    let rc = false;
    if (level <= Level.Error) {
      rc = true;
    }
    return rc;
  }

  isAuditLog(level: Level) {
    return (level === Level.Audit);
  }

  isExceptionLog(level: Level) {
    return (level === Level.Exception);
  }

  getLogTypeFromLevel(logLevel: Level): LogType {
    let logType = LogType.software;

    if (this.isAuditLog(logLevel)) {
      logType = LogType.audit;
    } else if (this.isExceptionLog(logLevel)) {
      logType = LogType.exception;
    }
    return(logType);
  }

  publishLog(logEntry: ClLogEntry) {
    const logLevel = logEntry.getLogLevel();
      if (this.isThisLevelLogged(logLevel)) {
        if (this.serviceInitialized) {
           const logType = this.getLogTypeFromLevel(logLevel);
           const endpoints = this.getEndpointsListForLogType(logType);
           let rc = true;
           if (endpoints) {
             for (const endpoint of endpoints) {
               endpoint.log(logEntry).subscribe(response => rc);
             }
           }
        } else {
          console.log(logEntry.logToString());
        }
      }
  }


  isThisLevelLogged(logLevel: Level): boolean {
    let isLogged = true;
    if (logLevel < this.loggingThreshold) {
      isLogged = false;
    }
    return (isLogged);
  }


  log(level: Level, msg: string, ...params: any[]) {
      this.publishLog(new ClLogEntry( level, msg, this.sourceContext, this.accountContext, this.userContext, ...params));
  }

  auditLog(msg: string, ...params: any[]) {

      const auditLog = new ClAuditLogEntry(msg, this.sourceContext, this.accountContext, this.userContext, ...params);
      this.publishLog(auditLog);
  }

  info(msg: string, ...params: any[]) {
      const infoLog = new ClLogEntry(Level.Info, msg, this.sourceContext, this.accountContext, this.userContext, ...params);
      this.publishLog(infoLog);
  }

  debug(msg: string, ...params: any[]) {
      this.publishLog(new ClLogEntry(Level.Debug, msg, this.sourceContext, this.accountContext, this.userContext, ...params));
  }

  warn(msg: string, ...params: any[]) {
      this.publishLog(new ClLogEntry(Level.Warn, msg, this.sourceContext, this.accountContext, this.userContext, ...params));
  }

  error(msg: string, ...params: any[]) {
      this.publishLog(new ClLogEntry(Level.Error, msg, this.sourceContext, this.accountContext, this.userContext,  ...params));
  }

  exception(msg: string, error: Error, ...params: any[]) {
      this.publishLog(new ClExceptionLogEntry(msg, error, this.sourceContext, this.accountContext, this.userContext,  ...params));
  }

   initLogging(): Promise<boolean> {
    if (!this.serviceInitialized) {
      try {
        return new Promise<boolean>((resolve) => {
          this.getLoggingConfig().subscribe((logCfg: IClLogConfig) => {
              this.cloneConfigData(this.loggingConfig, logCfg);
              this.updateLoggingConfig();
              resolve(true);
              this.serviceInitialized = true;
              setTimeout(() => {
                resolve(true);
              }, 2000);
            },
            error1 => {
              resolve(true);
              this.warn('Failed to load config for log service. Defaulting to console.log', error1);
            });
        });
      } catch (err) {
        this.exception('Failed to get config data for ClLogService ', err);
      }
    }
  }

  cloneConfigData(destConfig: ClLogConfig, ifData: IClLogConfig) {
    destConfig.logLevel = ifData.logLevel;
    for (const logger of ifData.loggers) {
      destConfig.loggers.push(logger);
    }

    for (const output of ifData.logOutputs) {
      destConfig.logOutputs.push(output);
    }
  }
  updateLoggingConfig() {
    this.initLogEndpointTypes();
    this.initLogOuputs();
    this.initLoggingThreshold();
  }

  private initLogEndpointTypes() {

    let logEndpoint: ClLogEndpoint;

    for (const logger of this.loggingConfig.loggers) {
      if (logger.enabled === 'true') {
        const loggerName = logger.name.toLowerCase();
        switch (loggerName) {
          case 'console':
            logEndpoint = new ClConsoleEndpoint();
            logEndpoint.setType(loggerName);
            this.endpoints.push(logEndpoint);
            break;
          case 'cloudlink-logger':
            logEndpoint = new S3LoggerEndpoint(this.batchLogger);
            logEndpoint.setType(loggerName);
            this.endpoints.push(logEndpoint);
            break;
          default:
            console.log('Unknown logger type specified' + loggerName);
            break;
        }
      }
    }
  }

  initLoggingThreshold() {
    const stringlevel = this.loggingConfig.logLevel.toString().toLowerCase();
    let index = 0;
    for (const lt of Object.keys(Level)) {
      if (Level[index].toLowerCase() === stringlevel) {
        this.loggingThreshold = Level[Level[index]];
        this.warn('Log threshold is set as: ' + stringlevel);
        break;
      }
      // since we have two keys for each enum entry, only increment on the number keys
      const parseIndex = parseInt(lt, 10);
      if (!(parseIndex !== parseIndex)) { // NaN is never equal to itself
        index++;
      }
    }
  }

  getEndpointTypeFromName(name: string) {
    let theEndpoint: ClLogEndpoint;

    for (const endpoint of this.endpoints) {
      if (name === endpoint.typeName.toLowerCase()) {
        theEndpoint = endpoint;
        break;
      }
    }
    return (theEndpoint);
  }

  addOutputsToRouting(logType: LogType, loggerList: string[]) {
    // get the Endpoint here if it exists

    for (const logger of loggerList) {
      const endpoint = this.getEndpointTypeFromName(logger);
      if (endpoint) {
        if (this.logRoutings[logType]) {
          this.logRoutings[logType].addEndpoint(endpoint);
        } else {
          this.logRoutings[logType] = new ClLogRoute(logType, endpoint);
        }
      }
    }
  }

  initLogOuputs() {

    for (const output of this.loggingConfig.logOutputs) {
      let logType: LogType;
      const type = output.type.toLowerCase();
        switch (type) {
          case (LogType[LogType.audit]):
            logType = LogType.audit;
            this.addOutputsToRouting(logType, output.loggerList);
            break;
          case (LogType[LogType.software]):
            logType = LogType.software;
            this.addOutputsToRouting(logType, output.loggerList);
           break;
          case (LogType[LogType.exception]):
            logType = LogType.exception;
            this.addOutputsToRouting(logType, output.loggerList);
            break;
        }
      }
  }

  getLoggingConfig(): Observable<ClLogConfig> {
    return(this.http.get<ClLogConfig>(this.configFile));
  }


  getEndpointsListForLogType(logType: LogType): ClLogEndpoint[] {
    let endpoints: ClLogEndpoint[];

    if (this.logRoutings[logType]) {
    endpoints = this.logRoutings[logType].endpoints;
    }
    return endpoints;
  }
}


