import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { AlertData, ALERT_DATA } from './cl-alertdata';

@Injectable({
  providedIn: 'root'
})

export class ClAlertService {
  private alertCounter = new Subject<number>();
  private demoMode = false;
  private newAlertCount = 0;

  constructor() {
  }

  public setDemoMode(demo: boolean) {
    if (demo === true) {
      this.demoMode = true;
      this.alertCounter.next(ALERT_DATA.length);
      setInterval(this.triggerAlertCountUpdate.bind(this), 10000);
    }
  }

  public getNewAlertCount(userId: string): Observable<number> {
    return (this.alertCounter);
  }


  public getAlertData(userId: string): AlertData[] {
    if (this.demoMode === true) {
      return (ALERT_DATA);
    } else {
      return [];
    }
  }

  public triggerAlertCountUpdate() {
    this.alertCounter.next(this.newAlertCount);
  }
}
