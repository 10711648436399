import { Injectable } from '@angular/core';
import { Config, AdminService, User } from '@mitel/cloudlink-sdk';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {
  private api: AdminService;
  private photoUrl: string;
  photoUrlChanged = new Subject<Array<string>>();

  constructor() {
  }

  public setCloudType(cloudType: string) {
    Config.cloud = cloudType;
    this.api = new AdminService();
  }

  public updateUserPhoto(userId: string, fileType: string, file: any): Promise<any> {
    const response = this.api.updateUserPhotoWithFileType({ userId: userId, body: file, fileType: fileType});
    return response;
  }

  // This method generates an event so every instance of avatar component gets updated with new photo url.
  setPhotoUrl(array: Array<string>) {
    // this.clog.debug("In AvatarService setPhotoUrl. url " + array[0] + ", userId" + array[1]);
    this.photoUrl = array[0];

    this.photoUrlChanged.next(array);
  }

  public getUser(userId: string): Promise<User> {
    return this.api.getUser({ userId });
  }

  public getUserByAccountId(accountId: string, userId: string, options?: any): Promise<User> {
    return this.api.getUserByAccountId({ accountId, userId, options });
  }

  getPhotoUrl() {
    return this.photoUrl;
  }

}
