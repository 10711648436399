import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {S3Service} from "./s3.service";
import {HttpMethodRequest, HttpResponse} from "@mitel/cloudlink-sdk";
import {S3Config} from "./s3-config";
import {S3Log, RequestBody, S3LogLevel} from "./s3.log";


enum URL {
  LOG = 0,
  FILE = 1,
  EXCEPTION = 2
}

@Injectable({
  providedIn: 'root'
})

export class LogBatchService {

  private logStore: S3Log[] = [];
  private logBackup: S3Log[] = [];
  private batchSize: number = 1;
  private logBatchTitle: string;
  private logBatchDescription: string;


  constructor(private s3: S3Service) {

  }

  public initS3Service( cloudType: string, s3: S3Config, title = 'Logs', description = 'Logs collected at Cloudlink Components')
  {
    this.s3.initS3Service(cloudType, s3);
    this.batchSize = this.s3.getS3URLRoutes().batchSize;
    this.logBatchTitle = title;
    this.logBatchDescription = description;
  }

  public log (log: S3Log) {
    this.addToBatch(log, URL.LOG);
  }

  public logException (log: S3Log) {
    this.addToBatch(log, URL.EXCEPTION);
  }

  //TODO - file url not in use right now best for files > 10Mb
  /*
  public logFile (log: ClLogEntry) {
    this.addToBatch(log, URL.FILE);
  }
  */

  private addToBatch(log: S3Log, urlroute: URL) {

    this.loadPipe(log);
    let totalBatchSize = this.logStore.length + this.logBackup.length;
   // console.log("logs in the batch now: %s", totalBatchSize);

    //if pipe is full enough pull out batch and send to server
    if (this.logStore.length >= this.batchSize) {
      let batch = this.loadBatch();
      this.sendBatch(urlroute, batch)
        .then(response => {
          console.log("LOG SUCCESS: batch sent to server. Creating new batch.");
        })
        .catch(error => {
          console.log("LOG FAILURE: Unable to send logs to server. Logs stored until issue is resolved", error);

          //we should still load the batch so it can be sent eventually
          this.loadBackupPipe(log);
        });
    }
  }


  private loadPipe(log: S3Log) {
    this.logStore.push(log);
  }

  private loadBackupPipe(log: S3Log) {
    this.logBackup.push(log);
  }

  private loadBatch (): S3Log[] {
    let batch: S3Log[] = this.logStore.splice(0, this.batchSize);
    return batch;
  }

  private sendBatch(urlroute: URL, batch: S3Log[]): Promise<HttpResponse> {

    let chosenUrl: string;
    let batchLogLevel: string;
    let requestBody: RequestBody;

    switch (urlroute) {
      case URL.EXCEPTION:
        chosenUrl = this.s3.getS3URLRoutes().urls.exceptions;
        requestBody = {
          source: "clcclogs",
          logLevel: S3LogLevel[S3LogLevel.Exception],
          type: "Exception Logs",
          title: this.logBatchTitle,
          description: this.logBatchDescription,
          logs: batch
        };
        break;
      //TODO - we don't log files as yet. This needs working out more.
      /*
      case URL.FILE:
        chosenUrl = this.s3.getS3URLRoutes().urls.files;
        break;
        */
      default:
        chosenUrl = this.s3.getS3URLRoutes().urls.logs;
        requestBody = {
          source: "clcclogs",
          logLevel: S3LogLevel[S3LogLevel.Info],
          type: "Logs",
          title: this.logBatchTitle,
          description: this.logBatchDescription,
          logs: batch
        };
        break;
    }

    let request: HttpMethodRequest = {url: chosenUrl};
    request.headers = {
      "Accept": "*/*",
      "content-type": "application/json"
    };
    request.body = requestBody;

    return this.s3.postObject(request);
  }
}
