import { Injectable } from '@angular/core';
import { userRoles } from './constants';

@Injectable({
  providedIn: 'root'
})
export class ClSideNavService {
  private roleMap = [
  	{
  		role: userRoles.GUEST,
  		rank: 0
  	},
  	{
  		role: userRoles.USER,
  		rank: 1
  	},
  	{
  		role: userRoles.ACCOUNT_ADMIN,
  		rank: 2
  	},
  	{
  		role: userRoles.PARTNER_ADMIN,
  		rank: 3
  	},
  	{
  		role: userRoles.PARTNER_GROUP_ADMIN,
  		rank: 4
  	}
  ];


  private currentUserRole: string;
  private defaultLanguages = [
    { name: 'English (US)', langCode: 'en', displayAbbr: 'EN (US)' },
    { name: 'French', langCode: 'fr', displayAbbr: 'FR' },
    { name: 'German', langCode: 'de', displayAbbr: 'DE' }
  ];

  private defaultCopyrightInfo = {
    year: '2019',
    company: 'Mitel Networks',
  }

  private defaultCompanyUrls = {
    support: 'https://help.mitel.io',
    legal: 'https://www.mitel.com/legal/cloudlink',
  }

  constructor() {
  }

  public setCurrentUserRole(role: string) {
  	this.currentUserRole = role;
  }

  public setRoleMap( roleMap: any )
  {
    this.roleMap = roleMap;
  }

  public getCurrentUserRole() {
  	return this.currentUserRole;
  }

  public getDefaultCopyrightInfo() {
    return (this.defaultCopyrightInfo);
  }

  public getDefaultCompanyUrls() {
    return (this.defaultCompanyUrls);
  }

  public shouldShowItem(minRole: string) {
  	return  !minRole || (this.currentUserRole &&
  	        this.getRoleRank(this.currentUserRole) >= this.getRoleRank(minRole));
  }

  public getRoleRank(role: string) {
  	let rank = -1;
  	this.roleMap.forEach(r => {
  		if (r.role === role) {
  			rank = r.rank;
  			return;
  		}
  	});

  	return rank;
  }

  public getDefaultLanguages() {
    return this.defaultLanguages;
  }
  
}
