import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ClLogService} from '../../cl-log/cl-log.service';

@Component({
  selector: 'cl-side-nav-sub-item',
  template: `<div
	class="menu-sub-item"
	[ngClass]="{'active': defaultItemId === item.id}"
	(click)="onItemClick()">
	<div class="left-indicator" *ngIf="defaultItemId === item.id"></div>
	<button type="button" 
			[id]="item.id"
			class="btn btn-link menu-text" 
			[ngbTooltip]="item && isEllipsisActive(item.id) ? item.name : '' "
			(keydown.enter)="$event.preventDefault(); onItemClick()" 
			(keydown.space)="$event.preventDefault(); onItemClick()" 
			[attr.tabindex]="showSideNav ? 0 : -1">{{ item.name }}
	</button>
</div>
`,
  styles: [`.menu-sub-item{height:50px;width:100%;padding:0 18px;outline:0!important;background-color:#11284c;cursor:pointer}.menu-sub-item.active,.menu-sub-item:hover{background-color:#11284c}.left-indicator{display:inline-block;width:5px;height:50px;background-color:#00a1e0;border-radius:2px;margin-right:-5px;float:left;margin-left:-18px}.menu-sub-item button{display:inline-block;font-family:'Open Sans';font-weight:600;font-size:12px;color:#fff;text-decoration:none;outline:0;line-height:50px;padding:0;margin-left:40px;border:none!important;height:inherit}.menu-sub-item button:focus{outline:0;box-shadow:0 0 0 .2rem rgba(59,153,252,.25);outline:-webkit-focus-ring-color auto 5px}.menu-sub-item.active button{color:#00a1e0}.menu-text{text-overflow:ellipsis;overflow:hidden;white-space:nowrap;max-width:145px;text-align:left}`]
})
export class ClSideNavSubItemComponent implements OnInit {
	@Input() item: any = {};
	@Input() defaultItemId: string;
  @Input() showSideNav: boolean;

	@Output() setItemDefault = new EventEmitter<string>();

  constructor(private log: ClLogService,
              private cd: ChangeDetectorRef) {}

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }    

  onItemClick() {
    this.log.debug('item clicked!', this.item);

  	// TODO navigate
    // Set default active
    this.setItemDefault.emit(this.item.id);
  }

  isEllipsisActive(id) {
    const thisElement = document.getElementById(id);
    return (thisElement ? thisElement.offsetWidth < thisElement.scrollWidth : false);
  }  

}
