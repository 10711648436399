import { Component, OnInit, OnDestroy, Input, ElementRef } from '@angular/core';
import { NgbDropdown, NgbAlertConfig, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { ClAlertService } from './cl-alert.service';
import { AlertData } from './cl-alertdata';
import { Subscription } from 'rxjs';
import { User } from '@mitel/cloudlink-sdk';
import { CloudLinkHeaderAuthService } from '../cl-header/cl-header-auth.service';


@Component({
  selector: 'cl-alert',
  template: `
<div ngbDropdown class="bell-area" alertDrop="ngbDropdown"  id="alertDrop" (document:click)="hideMenu()" (click)="$event.stopPropagation()">
  <button id="alert-bell" type="button" class="button-alert" (click)="onClick($event)" ngbDropdownAnchor>
     <span>
     	<img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjM0EzOTNBIiBzdHJva2Utd2lkdGg9Ii45NjkiPgogICAgICAgIDxwYXRoIGQ9Ik0xNS42NjUgMjcuNDE3YzEuOTMzIDAgMy41LTEuNTUgMy41LTMuNDZoLTdjMCAxLjkxIDEuNTY3IDMuNDYgMy41IDMuNDZ6Ii8+CiAgICAgICAgPHBhdGggc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNMjMuODMgMTkuMDM0bDEuNzQzIDEuNDI0LjU5IDMuNDMzSDUuMTY3bC41OTMtMy40MjYgMS43NC0xLjM5OHYtNC40MDVjMC0zLjIzIDEuOTE3LTYuMDE3IDQuNjg3LTcuMzA5LjE5NC0xLjczIDEuNjc3LTMuMDc0IDMuNDc4LTMuMDc0IDEuOCAwIDMuMjgzIDEuMzQ1IDMuNDc4IDMuMDc0IDIuNzcgMS4yOTIgNC42ODcgNC4wNzkgNC42ODcgNy4zMDl2NC4zNzJ6Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=" alt="alert">
     </span>
     <div *ngIf="showBadge" class="badge" id="badge">{{newAlertCount}}</div>
  </button>

    <div ngbDropdownMenu class="dropdown-menu"  [ngClass]=getDropdownClasses() aria-labelledby="alert-bell">
      <div id="alert-button-area" class="dd-button-area" *ngIf="hasAlerts()" >{{ 'cl_alert.notfication_title' | translate }}
        <div>
          <button id="dismiss-alerts" type="button" class="dismiss-button" (click)="dismissAll()">{{ 'cl_alert.dismiss_all' | translate }}</button>
        </div>
      </div>
      <div class="scrollable-menu">
        <div id="alert-list" class="alert-list-style" *ngFor="let alert of alerts">
            <ngb-alert [type]="alert.type" (close)="closeAlert(alert)">{{ alert.message }}</ngb-alert>
        </div>
      </div>
      <div class="empty-dd-area" *ngIf="!hasAlerts()" >{{ 'cl_alert.no_notifications' | translate }}</div>
    </div>
</div>

`,
  styles: [`.scrollable-menu{height:auto;max-height:400px;overflow-x:hidden;overflow-y:auto}.alert{display:flex}.cl-icon{background-color:#fff;vertical-align:top}.bell-area{display:inline-block}.dropdown-toggle::after{display:none}.caret-up{width:0;height:0;border-left:5px solid transparent;border-right:5px solid transparent;border-bottom:5px solid #000;box-sizing:border-box}.dismiss-button{text-align:center;position:relative;font-size:16px;color:#00f;outline:0;background:0 0;border:none;margin:0}.dd-button-area{position:relative;top:0;width:100%;height:66px;text-align:center;font-weight:700;font-size:20px;border-bottom-color:#212529}.empty-dd-area{text-align:center;font-size:20px}.dismiss-button:hover{color:red;cursor:pointer}.dropdown-menu{width:280px}.drop-center{left:-125px}.drop-left{left:auto;right:0}.drop-right{right:auto;left:0}.button-alert{background:0 0;border:none;padding:0;cursor:pointer}.alert-list-style{position:relative}.badge{position:absolute;right:0;top:-2px;background:#ee6723;border-radius:10px}`]
})
export class ClAlertComponent implements OnInit, OnDestroy {
  @Input() userId = '';
  @Input() align = '';
  @Input() demo = false;
  private alertSubscription: Subscription;
  showMenu = false;
  showBadge = false;
  alignLeft = false;
  alignRight = false;
  alignCenter = true;
  public alerts: AlertData[] = [];
  public newAlertCount: number;

  //  include headerAuth for launching URLs in future
  constructor(private alertService: ClAlertService,
    private headerAuth: CloudLinkHeaderAuthService,
    private el: ElementRef) {

  }

  ngOnInit() {

    if (this.demo === true) {
      this.alertService.setDemoMode(true);
    }
    this.alerts = this.alertService.getAlertData(this.userId);
    this.alertSubscription = this.alertService.getNewAlertCount(this.userId).subscribe(clNewAlerts => {
      if (this.demo === true) {
        this.newAlertCount = this.alerts.length;
      } else {
        this.newAlertCount = clNewAlerts;
      }
      this.showMsgCountBadge();
    });
    this.setAlignment();
  }

  public setAlignment() {
    if (this.align === 'left') {
      this.alignLeft = true;
      this.alignRight = false;
      this.alignCenter = false;
    } else if (this.align === 'right') {
      this.alignLeft = false;
      this.alignRight = true;
      this.alignCenter = false;
    } else {
      this.alignLeft = false;
      this.alignRight = false;
      this.alignCenter = true;
    }
    // drop through default will be drop-center
  }

  public getDropdownClasses() {
    return {
      'show': this.showMenu,
      'drop-left': this.alignLeft,
      'drop-right': this.alignRight,
      'drop-center': this.alignCenter
    };
  }
  public onClick(event) {
    if (!this.showMenu) {
      this.showBadge = false;
      this.alerts = this.alertService.getAlertData(this.userId);
      // if we are processing a click on the bell icon, prevent the page click propagation
      // so we don't affect another alert component that could be present (highly unlikley)
      event.stopImmediatePropagation();
    } else {
      setInterval(this.showMsgCountBadge, 10000);
    }
    this.showMenu = !this.showMenu;

  }

  public hideMenu() {
    this.showMenu = false;
    setInterval(this.showMsgCountBadge, 10000);
  }

  public closeAlert(alert: AlertData) {
    const index: number = this.alerts.indexOf(alert);
    this.alerts.splice(index, 1);
  }

  public dismissAll() {
    this.showMenu = false;
    if (this.demo === true) {
      this.alerts.length = 0;
      this.newAlertCount = 0;
    }

    setInterval(this.showMsgCountBadge, 10000);
  }

  public getNumAlerts(): number {
    return this.alerts.length;
  }

  public showMsgCountBadge() {
    // if the dropdown isn't open and there are new messages, show the badge
    if (!this.showMenu && this.newAlertCount > 0) {
      this.showBadge = true;
    } else {
      this.showBadge = false;
    }
  }
  public hasAlerts(): boolean {
    if (this.getNumAlerts() > 0) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    this.alertSubscription.unsubscribe();
  }
}

export interface IAlertData {
  id: number;
  type: string;
  message: string;
}


