import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader, TranslateCompiler } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { from } from 'rxjs';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { ClConsoleComponentsComponent } from './cl-console-components.component';
import { ClHeaderComponent } from './cl-header/cl-header.component';
import { AvatarComponent } from './avatar/avatar.component';
import { ClApplauncherComponent } from "./cl-applauncher/cl-applauncher.component";
import { ClAppcontainerComponent} from "./cl-applauncher/cl-appcontainer/cl-appcontainer.component";
import { DashboardCloudlinkAppComponent } from './dashboard-cloudlink-app/dashboard-cloudlink-app.component';
import { ClSideNavComponent } from './cl-side-nav/cl-side-nav.component';
import { ClSideNavItemComponent } from './cl-side-nav/cl-side-nav-item/cl-side-nav-item.component';
import { ClSideNavSubItemComponent } from './cl-side-nav/cl-side-nav-sub-item/cl-side-nav-sub-item.component';
import { ClAlertComponent } from './cl-alert/cl-alert.component';
import { ClCompanySwitcherComponent } from './cl-company-switcher/cl-company-switcher.component';
import { LogBatchService } from './cl-s3/batch.service';
import { ClLogService } from './cl-log/cl-log.service';
import {ClLogServiceStub} from '../service.test/cl-log.service.stub';


export function initAppFunc (theHeader: ClHeaderComponent) {
  console.log('[Cloudlink-Components] Starting initialization of console component library');
  return () => new Promise ((resolve, reject) => {
      resolve();
  });
}

export function initLoggingFunc (logServ: ClLogService) {
  console.log('Initializing Cloudlink Logging Service');
  return () => logServ.initLogging();
}

declare var System: any;


export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
      return from(System.import(`../assets/i18n/${lang}.json`));
  }
}


@NgModule({
  imports: [
    FormsModule,
    NgbModule,
    AngularFontAwesomeModule,
    CommonModule,
    NgbCollapseModule,
    HttpClientModule,
    TranslateModule.forRoot({
      // loader: {
      //     provide: TranslateLoader,
      //     useFactory: (CLLibHttpLoaderFactory),
      //     deps: [HttpClient]
      // }
      loader: {
        provide: TranslateLoader,
        useClass: (WebpackTranslateLoader),
        deps: [HttpClient]
    }
    })
  ],

  providers: [ClConsoleComponentsComponent, LogBatchService, ClLogService, ClLogServiceStub,
    {
      provide: APP_INITIALIZER,
      useFactory: initAppFunc,
      deps: [ ClHeaderComponent ],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initLoggingFunc,
      deps: [ ClLogService ],
      multi: true
    }
  ],
  declarations: [ClConsoleComponentsComponent,
                 ClHeaderComponent,
                 AvatarComponent,
                 ClApplauncherComponent,
                 DashboardCloudlinkAppComponent,
                 ClAppcontainerComponent,
                 ClSideNavComponent,
                 ClSideNavItemComponent,
                 ClSideNavSubItemComponent,
                 ClAlertComponent,
                 ClCompanySwitcherComponent
  ],
  exports: [ClConsoleComponentsComponent,
            ClHeaderComponent,
            AvatarComponent,
            ClApplauncherComponent,
            ClSideNavComponent,
            ClAlertComponent
  ]

})
export class ClConsoleComponentsModule { }
// export function CLLibHttpLoaderFactory(http: HttpClient) {
//   console.log("ClConsoleComponentsModule Load the language file");
//   return new MultiTranslateHttpLoader(http, [
//     {prefix: "/assets/cl-console-components/i18n/", suffix: ".json"}
// ]);
// }


