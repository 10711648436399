
export class Urls {
  constructor(){}
  logs = "https://logs.dev.api.mitel.io/2017-09-01/logs";
  files = "https://logs.dev.api.mitel.io/2017-09-01/files";
  exceptions = "https://logs.dev.api.mitel.io/2017-09-01/exception";
}

export class S3Config {

  constructor(batch = 1000, s3endpoints = new Urls()){
    this.batchSize = batch;
    this.urls = s3endpoints;
  }

  batchSize: number = 1000;
  urls: Urls = new Urls();
}
