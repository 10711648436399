export class ClLoggerDetail {
  name = '';
  enabled = 'false';
}

export class ClLogOutput {
  type = '';
  loggerList: string[] = [];
}

export interface IClLogConfig {
  loggers: {
    name: string;
    enabled: string
  }[];
  logOutputs: {
    type: string;
    loggerList: string[];
  }[];
  logLevel: string;
}

export class ClLogConfig {

  constructor() {
  }

  loggers: ClLoggerDetail[] = [];
  logOutputs: ClLogOutput[] = [];
  logLevel = 'Warn';
}
