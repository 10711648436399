import {Observable, of} from 'rxjs';
import {ClLogEntry, Level} from './cl-log-entry';
import {LogBatchService} from "../cl-s3/batch.service";
import {S3Log} from "../cl-s3/s3.log";

export abstract class ClLogEndpoint {
  typeName = '';
  outputDetail = '';
  enabled = false;
  abstract log(message: ClLogEntry): Observable<boolean>;
  getType(): string {
    return this.typeName;
  }

  setType(type: string) {
    this.typeName = type;
  }

  outputConsoleLog(message: ClLogEntry) {
    switch (message.getLogLevel()) {
      case (Level.Error):
        console.error(message.formatBaseMessage(), message.getAdditionalInfo());
        break;
      case (Level.Exception):
        console.error(message.formatBaseMessage(), message.getAdditionalInfo());
        break;
      case (Level.Warn):
        console.warn(message.formatBaseMessage(), message.getAdditionalInfo());
        break;
      case (Level.Audit):
        console.log('%c' + message.formatBaseMessage(), 'color:blue', message.getAdditionalInfo());
        break;
        case (Level.Debug):
          console.log(message.formatBaseMessage(), message.getAdditionalInfo());
          break;
      case (Level.Info):
      default:
        console.log(message.formatBaseMessage(), message.getAdditionalInfo());
    }
  }
}

export class ClConsoleEndpoint extends ClLogEndpoint {

  log(message: ClLogEntry): Observable<boolean> {

    this.outputConsoleLog(message);

    return of(true);
  }
}

export class S3LoggerEndpoint extends ClLogEndpoint {

  constructor (private logger: LogBatchService) {
    super();
  }

  log(message: ClLogEntry): Observable<boolean> {
    this.logger.log(this.convertToS3Log(message));
    return of(true);
  }

  convertToS3Log (log: ClLogEntry): S3Log {
    let s3Log = new S3Log(
      log.getLogLevel().toString(),
      log.message,
      log.timestamp,
      log.appCompId,
      log.msgId,
      log.userId,
      log.getAdditionalInfo()
    );
    return s3Log;
  }
}

export class ClFileEndpoint extends ClLogEndpoint {

  log(message: ClLogEntry): Observable<boolean> {

    // create a flat file logger - mainly for testing purposes
    return of(true);
  }
}
