import { HttpResponse, Config} from '@mitel/cloudlink-sdk';
import {Injectable} from "@angular/core";
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { S3Config } from "./s3-config";

@Injectable({
  providedIn: "root"
})

export class S3Service {

  public http;
  private S3Props : S3Config = new S3Config();

  constructor (private angHttp : HttpClient) {}

  public initS3Service ( cloudType: string, s3: S3Config) {
    Config.cloud = cloudType;
    this.S3Props = this.sanitizeInput(s3);
    this.http = Config.httpService;
  }

  private sanitizeInput (s3: S3Config): S3Config {
    let sanitizedS3 = new S3Config();
    let useDefaults = false;
    let regexnum: RegExp = /^[0-9]{1,4}$/;

    //validate batch size
    if ( s3.batchSize != null && regexnum.test(s3.batchSize + '') && s3.batchSize>1) {
      sanitizedS3.batchSize = s3.batchSize;
    } else {
      useDefaults = true;
    }

    //validate urls
    let regexurl: RegExp = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

    const keys = Object.keys(s3.urls);
    for (const key of keys) {
      if (key != null && regexurl.test(s3.urls[key])) {
        sanitizedS3.urls[key] = s3.urls[key];
      } else {
        useDefaults = true;
      }
    }

    if (useDefaults) {
      console.warn("S3LogServices: unable to parse user provided input for S3 logging. Using defaults instead");
    }
    // console.log("sanitized input: ", sanitizedS3);
    return sanitizedS3;
  }

  public getObject (params): Promise<HttpResponse> {
    var promise = new Promise<HttpResponse>( reject => {
      var response: HttpResponse;
      response = {
        statusCode: 405,
        headers: {
          "Accept": "*/*",
          "content-type": "application/json"
        }
      };
      reject(response);
    });
    return promise;
  }

  public postObject (params): Promise<HttpResponse> {
    console.log("sending these params: ", params);
    return this.http.post(params);
  }

  public putObject (params): Promise<HttpResponse> {
    return this.http.put(params);
  }

  public deleteObject (params): Promise<HttpResponse> {
    var promise = new Promise<HttpResponse>(reject => {
      var response: HttpResponse;
      response = {
        statusCode: 405,
        headers: {
          "Accept": "*/*",
          "content-type": "application/json"
        }
      };
      reject(response);
    });
    return promise;
  }

  public getS3URLRoutes (): S3Config {
    return this.S3Props;
  }
}
