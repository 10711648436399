export class  AlertData {
  id: number;
  type: string;
  message: string;
  dismissable: boolean;
}

export const ALERT_DATA = [
  {
    id: 1,
    type: 'info',
    message: 'Monthly billing report generated for Widget City',
    dismissable: true
  },
  {
    id: 2,
    type: 'info',
    message: 'A new contract has been added for Acme Inc.',
    dismissable: true
  },
  {
    id: 3,
    type: 'info',
    message: 'Entitlements have been updated for Joe Smith',
    dismissable: true,
  },
  {
    id: 4,
    type: 'danger',
    message: 'Profile update for Jane Smith failed',
    dismissable: true,
  },
  {
    id: 5,
    type: 'info',
    message: 'A new invoice has been received from Super Awesome Networks',
    dismissable: true,
  },
  {
    id: 6,
    type: 'info',
    message: 'Billing information successfully updated',
    dismissable: true
  },
  {
    id: 7,
    type: 'info',
    message: 'Please update your payment information',
    dismissable: true
  },
  {
    id: 8,
    type: 'info',
    message: 'System maintenance completed',
    dismissable: true
  }
];
