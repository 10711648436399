import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppContainer, APPS } from './app-container';
import {Subject} from "rxjs/internal/Subject";

@Injectable({
  providedIn: 'root'
})
export class ClApplauncherService {

  private appClick = new Subject<any>();
  private loading = new Subject<boolean>();

  constructor() {}

  /**
   * registers the app to be clicked maybe you want to know which app was clicked?
   * @param click
   */
  registerAppClick (click: any) {
    this.appClick.next(click);
  }

  getAppClick (): Observable<any> {
    return this.appClick.asObservable();
  }

  setLoader (load: boolean) {
    this.loading.next(load);
  }

  registerLoader (): Observable<boolean> {
    return this.loading.asObservable();
  }

  // DEMO CODE - load canned data
  getContainerData(): Observable<AppContainer[]> {
    return of(APPS);
  }
}
