import {Component, Injector, OnInit} from '@angular/core';
// import {DashboardCard} from '../dashboard-card';
// import {AbstractDashboardCard} from '../abstract-dashboard-card';
import { ClHeaderComponent } from '../cl-header/cl-header.component';
import { UserClaims } from '@mitel/cloudlink-sdk';
import { ClLogService} from '../cl-log/cl-log.service';

@Component({
  selector: 'app-dashboard-cloudlink-app',
  template: `
<div style="margin: 15px; padding:10px; border-style:solid; border-color:black; border-width:2px;" >
  <!-- <fa class="fa-w-16" name="iconClass" size="lg"></fa> -->
  <label>{{name}}</label>

  <br/>
  <p> This is the sample cloudlink application</p>
  <br/>
  <label>Name:</label><span>{{loggedInUser}}</span>
  <br/>
  <label>Email:</label><span>{{email}}</span>
  <br/>
  <button style="margin: auto;" color="primary"  (click)="launchApp(routerLink)">Launch</button>
  <br/>
</div>
`,
  styles: [`:host{height:100%;width:100%}`]
})
export class DashboardCloudlinkAppComponent implements OnInit {
 // export class DashboardCloudlinkAppComponent extends AbstractDashboardCard implements OnInit {

  // constructor(private injector: Injector, private clHeader: ClHeaderComponent) {
  //   super(injector.get(DashboardCard.metadata.NAME),
  //     injector.get(DashboardCard.metadata.ROUTERLINK),
  //     injector.get(DashboardCard.metadata.ICONCLASS),
  //     injector.get(DashboardCard.metadata.COLS),
  //     injector.get(DashboardCard.metadata.ROWS),
  //     injector.get(DashboardCard.metadata.COLOR));
  // }

    public name = 'Cloudlink App';
    public routerLink = 'http://localhost:4200';
    public iconClass = 'fa-comment';
    public loggedInUser = 'No one logged in';
    public email = '';
  constructor(private clHeader: ClHeaderComponent, private log: ClLogService) {
    let subscription = this.clHeader.headerAuth.getLoggedInUserClaims();
    subscription.subscribe((claims : UserClaims) => {this.handleUserClaims(claims)});
  }

  ngOnInit() {

  }

  public launchApp( url )
  {
    this.clHeader.openNewTab( url );
    //window.open(url);
  }

  private handleUserClaims(claims: UserClaims)
  {
    this.log.debug("Dashboard handle user claims ", claims);
    this.loggedInUser = claims.name;
    this.email = claims["email"];
    this.log.debug("****loggedInUser " + this.loggedInUser + " email " + this.email);
  }
}
