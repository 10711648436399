import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserClaims, Token } from '@mitel/cloudlink-sdk';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { ClLogService} from '../cl-log/cl-log.service';


@Injectable({
  providedIn: 'root',
})
export class CloudLinkHeaderAuthService {
  private authCodeQueryParam: string = 'code';
  private showToolbarQueryParam: string = 'showtoolbar';
  private invokedAccountIdQueryParam: string = 'accountId';
  private invokedRoleQueryParam: string = 'role';
  private stateQueryParam: string = 'state';
  private checkTokenQueryParam: string = 'checkToken';
  private tokenCookieName: string = 'X-Mitel-SPA';

  private authCode: string;
  private clientId: string;
  private clientURL: string;
  private scope: string;
  private cloudType: string;
  private authToken: Token;
  private toolbarIsVisible = true;
  private accountId: string;
  private appName: string;
  private role: string;
  private checkToken: boolean = false;


    constructor( public auth: AuthService, private clog: ClLogService) {
        this.parseInvokeURL(location);
        this.scope = "";
    }

  public setCloud( cloudType: string )
  {
    this.cloudType = cloudType;
    this.auth.setCloud( cloudType );
    // if ( this.authToken )
    // {
    //   this.auth.setToken(this.authToken);
    // }
  }
  public setAppClientId( clientId: string )
  {
    this.clientId = clientId;
  }

  public getAppClientId()
  {
    return this.clientId;
  }

  public setApplicationName(theAppName: string)
  {
    this.appName = theAppName;
  }

  public isToolbarVisible()
  {
    return this.toolbarIsVisible;
  }

  public shouldCheckToken(): boolean
  {
    return this.checkToken;
  }
  public getLoggedInUserClaims() : Observable<UserClaims> {

    let subscription;

        subscription = new Observable((observer) => {
            this.getUserClaims().then((claims) => {
               // this.clog.debug('isUserLoggedIn called getUserClaims and received claims ', claims);
                observer.next(claims);
                observer.complete();
            });
        })

        return subscription;
    }

    public openNewApplicationInTab( url )
    {
        window.open(url);
    }

  public getUserClaims() : Promise<UserClaims>
  {
    if (this.authCode) 
    {
      this.clog.debug("Logging in with an Auth Code ", this.authCode);
      try {
        return this.auth.login(this.authCode, this.clientId, this.clientURL, this.scope).then(token => {
          if (token) {
           // this.authToken = token;
            this.clog.debug("Authcode: getUserClaims this.auth.getToken() == ", token);
            return this.auth.whoAmI(token).then(claims => claims as UserClaims,
              err => {
                this.clog.error('getUserClaims: Failure from auth.whoAmI() after loogin with auth code ', err);
                this.clog.debug("Now Redirect to login again ");
                this.redirectToLogin();
                return null;
              });
            } else {
                this.clog.debug("AuthCode: No token - redirecting to login");
                this.redirectToLogin();
          }
        });
      } catch (e) {
        this.clog.error('getUserClaims this.AuthCode threw: ', e);
        console.error('getUserClaims this.AuthCode threw: ', e);
        try {
          this.auth.logout();
        } catch (e2){
          this.clog.exception('getUserClaims logout threw: ', e);
        }
        this.redirectToLogin();
        return Promise.reject(e);
      }
    } else {
      if ( this.shouldCheckToken() )
      {
        // the toolbar was explicitly asked to use the cookie token. If it does not exist, then 
        // the app is sent to
        if ( this.useCookieToken() == false )
        {
          this.clog.debug("the cookie token was not found - Now Redirect to login");
          this.redirectToLogin();
        }
      }
      return this.auth.getToken().then(token => {
        if (token) {
            //this.authToken = token;
            this.clog.debug("getUserClaims this.auth.getToken() == ", token );
            return this.auth.whoAmI(token).then(claims => {
                    if ( claims ) {
                      this.clog.debug("There are user claims ", claims);
                      this.clog.debug("The account Id in the user claims is ", claims.accountId);
                      this.clog.debug("The exp property is ", claims.exp);
                        const d : Date = new Date();
                        var now_in_seconds = d.getTime() / 1000;
                        // need to check if the token has expired
                        if ( !claims.exp ) {
                          this.clog.debug("There are no claims EXP - redirect to login");
                            this.redirectToLogin();
                        }
                        var expired = +claims.exp
                        if ( expired <= now_in_seconds ) {
                          this.clog.debug("The claims EXP is less than current time - redirect to login");
                            this.redirectToLogin();
                        }
                        // new check - now look for a MiNounce
                        if ( this.isSessionValid() )
                          return claims;
                        else
                        {
                          this.clog.debug("The session nonce is invalid - redirect to login");
                          this.redirectToLogin();
                        }
                    } else {
                      this.clog.debug("There are no claims - redirect to login");
                        this.redirectToLogin();
                    }
                    return claims;
                },
              err => {
                this.clog.exception('getUserClaims: Failure from auth.whoAmI() ', err);
                this.clog.debug("Now Redirect to login");
                this.redirectToLogin();
                return null;
              }
            );
          } else {
              this.clog.debug("getUserClaims: there is no token, lets check for auth code");
              //if there is an auth code, then login with the code
              this.clog.debug("getUserClaims: AuthCode: no authcode redirecting to login");
              this.redirectToLogin();
            }
        });
    }
  }

  useCookieToken(): boolean
  {
    // check for the the special cookie that should contain the token
    let passedToken = this.getCookie(this.tokenCookieName);

    //if the cookie does not exist or the token is not existing, redirect to login
    if ( passedToken == null )
      return false;

    // write the token to local storage
    this.auth.setToken( JSON.parse(passedToken) );
    return true;
  }

  isSessionValid(): boolean
  {
    let nonce = this.getCookie('miNonce');
    if ( nonce == null )
      return true;

    if ( nonce && nonce.length > 0 && nonce !== '0' )
    {
      return true;
    }
    return false;
  }
  public getCookie(name: string) {
    let ca: Array<string> = this.getAllDocumentCookies();
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s+/g, '');
        if (c.indexOf(cookieName) == 0) {
            return c.substring(cookieName.length, c.length);
        }
    }
    return null;
  }

  //for testing purposes only
  public getAllDocumentCookies(): Array<string> 
  {
    return document.cookie.split(';');
  }

    // redirects the page to the login app
    public redirectToLogin() {

      // store the current URL for use after the login page redirects back to this app
    //  window.sessionStorage.setItem('invoking_uri', location.href);

      let invoking_uri = window.sessionStorage.getItem('invoking_uri');
      if (invoking_uri == null) {
          invoking_uri = location.href;
          window.sessionStorage.setItem('invoking_uri', invoking_uri);
      }

      var authCodeURL = (this.cloudType == 'dev' ?  'https://auth.dev.mitel.io/authorize' : 'https://auth.mitel.io/authorize'  );
    //  var authCodeURL = (this.cloudType == 'dev' ?  'http://localhost:4300/authorize' : 'https://auth.mitel.io/authorize'  );
      var encodedAppName = encodeURIComponent(this.appName);
      var stateVar = {
        role: undefined,
        accountId: undefined,
        showToolbar: undefined
      };

      if ( this.role != null && this.role != '' )
        stateVar.role = this.role;
   
      if ( this.accountId != null && this.accountId != '' )
        stateVar.accountId = this.accountId;

      stateVar.showToolbar = this.toolbarIsVisible;

      let theState = encodeURIComponent( JSON.stringify(stateVar) );

      var newurl = authCodeURL + "?client_id=" + this.clientId + "&response_type=code&redirect_uri=" + this.clientURL + "&state=" + theState + "&app_name=" + encodedAppName;

      this.clog.warn( "REDIRECT TO LOGIN ",  newurl );
        this.setWindowLocationUrl(newurl);
    }

    // when the header is loaded, parse the client URL to get the client URL and to extract any code that was included
    public parseInvokeURL( theLocation ) {
      // this.clog.debug("parseInvokeURL start location =", theLocation.href);
      // this.clog.debug("parseInvokeURL start location.search =", theLocation.search);
      // console.log("parseInvokeURL start location =", theLocation.href);
      // console.log("parseInvokeURL start location.search =", theLocation.search);
      var theState = theLocation.href && theLocation.href.indexOf(this.stateQueryParam + '=') > 0 ?
        theLocation.href.split(this.stateQueryParam + '=')[1].split('&')[0] : "";
      // console.log("The state received before decoding is ", theState);

      if ( theState != "" )
      {
        let decoded = decodeURIComponent(theState);
        // console.log("This is decoded ", decoded );

        if ( decoded.indexOf('{') >= 0 )
        {
          let theStateJson = JSON.parse( decoded );
          // console.log("The state received is ", theStateJson);
          // console.log("The state showToolbar is ", theStateJson.showToolbar);
          if ( theStateJson.showToolbar !== undefined )
          {
            if ( (theStateJson.showToolbar == "false") || (theStateJson.showToolbar === false) )
            {
              // console.log("Show toolbar is false")
              this.toolbarIsVisible = false;
            }
          }
          
          if ( theStateJson.accountId )
            this.accountId  = theStateJson.accountId;

          if ( theStateJson.role )
            this.role = theStateJson.role;
        }
        else
        {
          this.clog.error("Not expecting this type of state ", decoded);
        }
      }


      // in parseInvokeURL(), changed theLocation.search to theLocation.href  because former would be empty when href contained /#/
        var showToolbar = theLocation.href && theLocation.href.indexOf(this.showToolbarQueryParam + '=') > 0 ?
            theLocation.href.split(this.showToolbarQueryParam + '=')[1].split('&')[0] : "";

        if ( showToolbar != "" )
        {
            if ( showToolbar == "false" ) //make sure it is not a string
            {
                // this.clog.debug("setting showToolbar to FALSE");
                this.toolbarIsVisible = false;
            }
        }

        var theCode = theLocation.href && theLocation.href.indexOf(this.authCodeQueryParam + '=') > 0 ?
        theLocation.href.split(this.authCodeQueryParam + '=')[1].split('&')[0] : "";

        if ( theCode )
        {
            this.authCode = theCode;
            //Check for state variable
        }
        else
        {
            // there is no code. Look for parent passed parameters
           var theAccountId = theLocation.href && theLocation.href.indexOf(this.invokedAccountIdQueryParam + '=') > 0 ?
                 theLocation.href.split(this.invokedAccountIdQueryParam + '=')[1].split('&')[0] : "";

            if ( theAccountId )
            {
              this.clog.debug("Here is the extracted account id", theAccountId);
              this.accountId = theAccountId;
            } 

            var theRole = theLocation.href && theLocation.href.indexOf(this.invokedRoleQueryParam + '=') > 0 ?
            theLocation.href.split(this.invokedRoleQueryParam + '=')[1].split('&')[0] : "";

            if ( theRole )
            {
              this.clog.debug("Here is the extracted role", theRole);
              this.role = theRole;
            } 

            var checkTokenFlag = theLocation.href && theLocation.href.indexOf(this.checkTokenQueryParam + '=') > 0 ?
            theLocation.href.split(this.checkTokenQueryParam + '=')[1].split('&')[0] : "";

            if ( checkTokenFlag != "" )
            {
                if ( checkTokenFlag == "true" ) //make sure it is not a string
                {
                    this.checkToken = true;
                }
            }
        }
        
        this.clientURL = theLocation.origin;
      // this.clog.debug("parseInvokeURL end: client URL= " + this.clientURL + " code= " + this.authCode);
    }

    public getAuthCode() {
        return this.authCode;
    }

    public getInvokedAccountId() {
      return this.accountId;
    }

    public getInvokedRole() {
      return this.role;
    }

    public getClientUrl()
    {
        return this.clientURL;
    }

    // This method will not be covered by code coverage since it redirects a url.
    // It affects unit testing, so a decision is to ignore it.
    /* istanbul ignore next */
    public setWindowLocationUrl( url: string ) {
      this.clog.debug('Setting window.location.href to ', url);
        setTimeout(function() { window.location.href = url; }, 100);
    }

    /**
     * will call setWindowLocationUrl() with the auth mitel.io/logout page
     */
    public redirectToLogout() {

    //  var authCodeURL = (this.cloudType == 'dev' ?  'http://localhost:4300/logout' : 'https://auth.mitel.io/logout'  );

    var authCodeURL = (this.cloudType == 'dev' ?  'https://auth.dev.mitel.io/logout' : 'https://auth.mitel.io/logout'  );
    var encodedAppName = encodeURIComponent(this.appName);
    var newurl = authCodeURL + "?client_id=" + this.clientId + "&response_type=code&redirect_uri=" + this.clientURL + "&app_name=" + encodedAppName;
      
   // this.clog.debug( "REDIRECT TO logout ",  newurl );
      this.setWindowLocationUrl(newurl);
  }

  // set the special token cookie with the current token used by the application
  setTokenCookie(): Promise<Token>
  {
    return this.auth.getToken().then(token => {
      if (token) 
      {
        var date = new Date();
        date.setTime(date.getTime()+(5*60*1000));
        document.cookie = this.tokenCookieName + "=" + JSON.stringify(token) + 
          "; expires=" + date.toUTCString() + 
          ";domain=.mitel.io;path=/";
      }
      return token;
    });
  }

}

